import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";

/**
 * PageLoader Component
 * Displays a loading spinner or fallback UI while Auth0 state is being checked.
 */
const PageLoader = (): React.ReactElement => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

    // Show a loading spinner while Auth0 is initializing
    if (isLoading) {
        return (
            <Box sx={styles.container}>
                <div style={styles.spinner}></div>
                <p>Loading...</p>
            </Box>
        );
    }

    // If user is not authenticated, redirect to login
    if (!isAuthenticated) {
        loginWithRedirect();
        return (
            <Box sx={styles.container}>
                <p>Redirecting to login...</p>
            </Box>
        );
    }

    // Once authenticated, render the children components
    return <></>;
};

export default PageLoader;

// Inline styles for simplicity
const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
        color: "#333",
    },
    spinner: {
        width: "50px",
        height: "50px",
        border: "5px solid #ccc",
        borderTop: "5px solid #007bff",
        borderRadius: "50%",
        animation: "spin 1s linear infinite",
    },
};
