import { REACT_APP_BACKEND_PROTECTED_URL } from "../../configs/config";
import { IPrompt } from "../../models/interfaces/prompt/IPrompt";
import { IAgentPromptView } from "../../models/interfaces/workbench/agent/IAgentPromptView";
import GetRequestHeader from "../../utils/auth_utils";
import { ParamUtils } from "../../utils/param_utils";
const GetPromptUrl = (promptId: string, versionId?: number) => {
    if (versionId) {
        return `${REACT_APP_BACKEND_PROTECTED_URL}/prompt/${promptId}/version/${versionId}`;
    } else {
        return `${REACT_APP_BACKEND_PROTECTED_URL}/prompt/${promptId}`;
    }
};

const DeletePromptUrl = (promptId: string) => `${REACT_APP_BACKEND_PROTECTED_URL}/prompt/${promptId}`;
const ResetPromptVersionUrl = (promptId: string, versionId: number) =>
    `${REACT_APP_BACKEND_PROTECTED_URL}/prompt/${promptId}/version/${versionId}`;
const GetAgentPromptUrl = (agentId: string) => `${REACT_APP_BACKEND_PROTECTED_URL}/agentPrompt/${agentId}`;
const DeleteAgentPromptUrl = (agentId: string) => `${REACT_APP_BACKEND_PROTECTED_URL}/agentPrompt/${agentId}`;
const ListPromptVersionsUrl = (userId: string, promptId: string) => {
    return `${REACT_APP_BACKEND_PROTECTED_URL}/prompt/${promptId}/version?ownerId=${userId}`;
};

const GetOptions = (authToken?: string) => GetRequestHeader({ method: "GET", authToken: authToken });

const DeleteOptions = (authToken?: string) => GetRequestHeader({ method: "DELETE", authToken: authToken });

const ResetOptions = (body: any, authToken?: string) =>
    GetRequestHeader({
        method: "PUT",
        body: JSON.stringify(body),
        authToken: authToken,
    });

const ListAgentVersionsOptions = (authToken?: string) => GetRequestHeader({ method: "GET", authToken: authToken });

const ListAgentVersionsUrl = (userId: string, agentId: string): string => {
    ParamUtils.ThrowIfParamUndefinedOrNull({
        paramName: "userId",
        paramValue: userId,
        errorMsg: "userId is undefined or null",
    });
    ParamUtils.ThrowIfParamUndefinedOrNull({
        paramName: "agentId",
        paramValue: agentId,
        errorMsg: "agentId is undefined or null",
    });

    return `${REACT_APP_BACKEND_PROTECTED_URL}/agent/${agentId}/version?ownerId=${userId}`;
};

const loadPrompt = async (promptId: string, versionId?: number, authToken?: string): Promise<IPrompt> => {
    const resp = await fetch(GetPromptUrl(promptId, versionId), GetOptions(authToken));
    if (!resp.ok) throw new Error("API request failed: " + resp.text);
    const data = await resp.json();
    const promptObj: IPrompt = {
        ...data,
    };
    return promptObj;
};

const loadAgentPrompt = async (agentId: string, authToken?: string): Promise<IAgentPromptView> => {
    const resp = await fetch(GetAgentPromptUrl(agentId), GetOptions(authToken));
    if (!resp.ok) throw new Error("API request failed: " + resp.text);
    const data = await resp.json();
    const promptObj: IAgentPromptView = {
        ...data,
    };
    return promptObj;
};

const deletePrompt = async (promptId: string, authToken?: string): Promise<IPrompt> => {
    const resp = await fetch(DeletePromptUrl(promptId), DeleteOptions(authToken));
    if (!resp.ok) throw new Error("API request failed: " + resp.text);
    const data = await resp.json();
    const promptObj: IPrompt = {
        ...data,
    };
    return promptObj;
};

const deleteAgentPrompt = async (agentId: string, authToken?: string): Promise<IAgentPromptView> => {
    const resp = await fetch(DeleteAgentPromptUrl(agentId), DeleteOptions(authToken));
    if (!resp.ok) throw new Error("API request failed: " + resp.text);
    const data = await resp.json();
    const agentPromptObj: IAgentPromptView = {
        ...data,
    };
    return agentPromptObj;
};

const resetPromptVersion = async (prompt: IPrompt, authToken?: string): Promise<IPrompt> => {
    const resp = await fetch(
        ResetPromptVersionUrl(prompt.promptId, prompt.versionId as number),
        ResetOptions(prompt, authToken)
    );
    if (!resp.ok) throw new Error("API request failed: " + resp.text);
    const data = await resp.json();
    const promptObj: IPrompt = {
        ...data,
    };
    return promptObj;
};

export {
    deleteAgentPrompt,
    DeleteOptions,
    deletePrompt,
    GetOptions,
    ListAgentVersionsOptions,
    ListAgentVersionsUrl,
    ListPromptVersionsUrl,
    loadAgentPrompt,
    loadPrompt,
    resetPromptVersion,
};
