import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import * as React from "react";
import { IPrompt } from "../../../models/interfaces/prompt/IPrompt";
import { LoadEntityTypeEnum } from "../../../types/entity/LoadEntityEnum";
import { GetOptions, ListPromptVersionsUrl, resetPromptVersion } from "../../prompt/ManagePrompt";
import { VersionTimeline } from "./VersionTimeline";

interface PromptVersionTimelineProps {
    props: {
        selectedPrompt: IPrompt;
        setSelectedPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
        currentPrompt: IPrompt;
        setCurrentPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
        isSelectVersionModalOpen: boolean;
        setIsSelectVersionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        userId: string;
    };
}

const PromptVersionTimeline: React.FC<PromptVersionTimelineProps> = ({ props }) => {
    const { getAccessTokenSilently } = useAuth0();

    const fetchAvailableVersions = async () => {
        if (props.currentPrompt === undefined) return [] as IPrompt[];

        const resp = await fetch(
            ListPromptVersionsUrl(props.userId, props.currentPrompt.promptId),
            GetOptions(await getAccessTokenSilently())
        );

        if (!resp.ok) throw new Error("API request failed: " + resp.text);

        const data: IPrompt[] = await resp.json();
        return data;
    };

    const selectPromptVersion = async (targetPrompt: IPrompt): Promise<void> => {
        if (targetPrompt === undefined || props.currentPrompt.versionId === targetPrompt.versionId)
            // No change
            return;
        const promptObj = await resetPromptVersion(targetPrompt);
        props.setCurrentPrompt(promptObj);
    };

    return (
        <Box id="prompt-select">
            <VersionTimeline
                props={{
                    entity: {
                        entityType: LoadEntityTypeEnum.Prompt,
                        currentEntity: props.currentPrompt,
                        setCurrentEntity: props.setCurrentPrompt,
                        getCurrentEntityId: () => props.currentPrompt.promptId,
                        getCurrentEntityName: () => props.currentPrompt.promptName,
                        getCurrentEntityVersionId: () => props.currentPrompt.versionId,
                        fetchAvailableVersions: fetchAvailableVersions,
                        submitSelectVersion: selectPromptVersion,
                    },
                    isSelectVersionModalOpen: props.isSelectVersionModalOpen,
                    setIsSelectVersionModalOpen: props.setIsSelectVersionModalOpen,
                    userId: props.userId,
                }}
            />
        </Box>
    );
};

export { PromptVersionTimeline };
