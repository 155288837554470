import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from "@mui/material";

function Profile() {
    const { isAuthenticated, logout, user } = useAuth0();
    return isAuthenticated ? (
        <Box>
            Howdy, <b>{user?.name}</b>
            <Button
                variant="text"
                color="inherit"
                onClick={() => {
                    logout({
                        logoutParams: {
                            returnTo: window.location.origin,
                        },
                    });
                }}
            >
                Log out
            </Button>
        </Box>
    ) : null;
}

export default Profile;
