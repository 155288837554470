import { useState } from "react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../../configs/config";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";

import { useAuth0 } from "@auth0/auth0-react";
import { IPrompt } from "../../../../models/interfaces/prompt/IPrompt";
import { IAgentPromptView } from "../../../../models/interfaces/workbench/agent/IAgentPromptView";
import { LoadEntityTypeEnum } from "../../../../types/entity/LoadEntityEnum";
import { LoadEntityOptionBaseType } from "../../../../types/entity/LoadEntityOptionBaseType";
import { deleteAgentPrompt, loadPrompt } from "../../../prompt/ManagePrompt";
import LoadEntityFramework from "../framework/LoadEntityFramework";

interface ILoadAgentProps {
    props: {
        userId: string;
        isLoadModalOpen: boolean;
        setIsLoadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        currentAgent: IAgent | undefined;
        setCurrentAgent: React.Dispatch<React.SetStateAction<IAgent | undefined>>;
        setSelectedAgents: React.Dispatch<React.SetStateAction<{ [agentId: string]: IAgent }>>;
        currentPrompt: IPrompt;
        setCurrentPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
        selectedTabKey: string;
        setEntityType: React.Dispatch<React.SetStateAction<LoadEntityTypeEnum>>;
    };
}

const LoadAgent = ({ props }: ILoadAgentProps) => {
    const { getAccessTokenSilently } = useAuth0();

    const [availableAgentRequest, setAvailableAgentRequest] = useState({
        loading: false,
        availableOptions: [] as IAgent[],
        error: null as unknown as Error,
    });
    const [selectedOptionId, setSelectedOptionId] = useState("");
    const [searchText, setSearchText] = useState("");

    const listAgentUrl = `${REACT_APP_BACKEND_PROTECTED_URL}/agent/filter?ownerId=${props.userId}`;

    const deleteAgent = async (agentId: string) => {
        const index = availableAgentRequest.availableOptions.findIndex((agent) => agent.agentId === selectedOptionId);
        if (index === null || index === undefined || index < 0) throw new Error(`Index ${index} is invalid`);
        const deletedObj: IAgentPromptView = await deleteAgentPrompt(agentId);
        props.setCurrentAgent(undefined);
        props.setSelectedAgents((prev) => {
            const next = { ...prev };
            delete next[availableAgentRequest.availableOptions[index].agentId];
            return next;
        });
        props.setCurrentPrompt({ promptContent: "" } as IPrompt);
        return deletedObj;
    };

    const loadAgentPrompt = async (agentId: string): Promise<IPrompt> => {
        const index = availableAgentRequest.availableOptions.findIndex((can) => can.agentId === agentId);
        if (index === null || index === undefined || index < 0) throw new Error(`Index ${index} is invalid`);
        const ag = availableAgentRequest.availableOptions[index];
        props.setCurrentAgent(availableAgentRequest.availableOptions[index]);
        props.setSelectedAgents((prev) => {
            return {
                ...prev,
                [availableAgentRequest.availableOptions[index].agentId]: availableAgentRequest.availableOptions[index],
            };
        });

        const authToken = await getAccessTokenSilently();
        if (authToken === undefined || authToken === null || authToken === "") throw new Error("Auth token is invalid");
        const agentPrompt = await loadPrompt(ag.promptId, ag.versionId, authToken);
        props.setCurrentPrompt(agentPrompt);
        setSelectedOptionId(ag.agentId);
        return agentPrompt;
    };

    return (
        <LoadEntityFramework
            userId={props.userId}
            isLoadModalOpen={props.isLoadModalOpen}
            setIsLoadModalOpen={props.setIsLoadModalOpen}
            entity={
                {
                    entityType: LoadEntityTypeEnum.Agent,
                    selectedOptionId: selectedOptionId,
                    setSelectedOptionId: setSelectedOptionId,
                    searchText: searchText,
                    setSearchText: setSearchText,
                    currentEntity: props.currentAgent,
                    setCurrentEntity: props.setCurrentAgent,
                    deleteEntity: deleteAgent,
                    loadEntity: loadAgentPrompt,
                    listEntityUrl: listAgentUrl,
                    availableEntityRequest: availableAgentRequest,
                    setAvailableEntityRequest: setAvailableAgentRequest,
                } as LoadEntityOptionBaseType<IAgent>
            }
            selectedTabKey={props.selectedTabKey}
            setEntityType={props.setEntityType}
        />
    );
};

export default LoadAgent;
