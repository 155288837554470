import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

function LoginButton() {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    return isAuthenticated ? null : (
        <Button
            variant="text"
            color="inherit"
            onClick={async () =>
                await loginWithRedirect({
                    appState: {
                        returnTo: window.location.pathname,
                    },
                })
            }
        >
            Log in
        </Button>
    );
}

export default LoginButton;
