import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import LoginButton from "./LoginBtn";
import Profile from "./ProfileBtn";

function LoginLayout() {
    const { isLoading } = useAuth0();
    if (isLoading) return <div>Loading...</div>;
    return (
        <Box>
            <Box sx={{ flexGrow: 0, xs: "block" }}>
                <Profile />
            </Box>
            <Box sx={{ flexGrow: 0, xs: "block" }}>
                <LoginButton />
            </Box>
        </Box>
    );
}

export default LoginLayout;
