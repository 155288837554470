const GetRequestHeader = ({ method, body, authToken }: { method: string; body?: string; authToken?: string }) => {
    return {
        method: method,
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken ? `Bearer ${authToken}` : "",
            mode: "cors",
        },
        body: body ? body : undefined,
    };
};

export default GetRequestHeader;
