import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import PageLoader from "./components/common/PageLoader";
import MasterLayout from "./components/master/MasterLayout";
import CallbackPage from "./pages/main-layout/Callback";
import Chat from "./pages/main-layout/Chat";
import Discovery from "./pages/main-layout/Discovery";
import Home from "./pages/main-layout/Home";
import Workbench from "./pages/main-layout/Workbench";
import theme from "./themes/theme";

function App(): React.ReactElement {
    const { isLoading } = useAuth0();
    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        );
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                <Route path="" element={<MasterLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="workbench" element={<ProtectedRoute component={Workbench} />} />
                    <Route path="chat" element={<ProtectedRoute component={Chat} />} />
                    <Route path="discovery" element={<ProtectedRoute component={Discovery} />} />
                    <Route path="/callback" element={<CallbackPage />} />
                </Route>
            </Routes>
        </ThemeProvider>
    );
}
export default App;
