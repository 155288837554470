import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Modal,
    Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { type Socket } from "socket.io-client";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../configs/config";
import { IAgent } from "../../../models/interfaces/workbench/agent/IAgent";
import { IConversation } from "../../../models/interfaces/workbench/chat/IChatSession";
import GetRequestHeader from "../../../utils/auth_utils";
import { agentList, modalBoxStyle } from "./SelectAgentModalStyle";

interface SelectAgentModalProps {
    props: {
        userId: string;
        isSelectAgentModalOpen: boolean;
        setIsSelectAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setConversation: React.Dispatch<React.SetStateAction<IConversation>>;
        selectedAgents: { [key: string]: IAgent };
        setSelectedAgents: React.Dispatch<React.SetStateAction<{ [key: string]: IAgent }>>;
        socket: Socket | undefined;
        chatSessionId: string;
        startNewConversationAsync: (agentList: { [agentId: string]: number }) => Promise<void>;
        getPrompt: () => string;
    };
}

const SelectAgentModal: React.FC<SelectAgentModalProps> = ({ props }) => {
    const [availableAgentsRequest, setAvailableAgentsRequest] = useState({
        loading: false,
        availableAgents: [] as IAgent[],
        error: null as unknown as Error,
    });

    const { getAccessTokenSilently } = useAuth0();

    // const url = `${BACKEND_BASE_URL}/prompt/filter?ownerId=${props.userId}`;
    const url = `${REACT_APP_BACKEND_PROTECTED_URL}/agent/filter?ownerId=${props.userId}`;

    useEffect(() => {
        const fetchAvailableAgents = async () => {
            try {
                if (props.isSelectAgentModalOpen === false) return;
                setAvailableAgentsRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: true,
                    };
                });
                const GetOptions = async () =>
                    GetRequestHeader({
                        method: "GET",
                        authToken: await getAccessTokenSilently(),
                    });
                const resp = await fetch(url, await GetOptions());
                if (!resp.ok) throw new Error("API request failed: " + resp.statusText);
                const data = await resp.json();
                setAvailableAgentsRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                        availableAgents: (data as IAgent[]).sort((a, b) => {
                            return a.agentName.localeCompare(b.agentName);
                        }),
                        error: null as unknown as Error,
                    };
                });
            } catch (error: Error | any) {
                console.log(error);
                setAvailableAgentsRequest((prevState) => {
                    return {
                        ...prevState,
                        availableAgents: [],
                        loading: false,
                        error: error,
                    };
                });
            }
        };
        fetchAvailableAgents();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSelectAgentModalOpen, url]);

    const handleAgentSelection = (index: number) => {
        if (index === null || index === undefined) return;
        const agentId = availableAgentsRequest.availableAgents[index].agentId;
        if (props.selectedAgents[agentId] === undefined) {
            props.setSelectedAgents((prevState) => {
                return {
                    ...prevState,
                    [agentId]: availableAgentsRequest.availableAgents[index],
                };
            });
        } else {
            props.setSelectedAgents((prevState) => {
                const newState = { ...prevState };
                delete newState[agentId];
                return newState;
            });
        }
    };

    const handleSubmitSelectedAgents = async () => {
        try {
            const selectedAgents = Object.values(props.selectedAgents)
                .map((x) => {
                    return {
                        agentId: x.agentId,
                        versionId: x.versionId,
                    };
                })
                .reduce((acc, x) => {
                    acc[x.agentId] = x.versionId;
                    return acc;
                }, {} as { [agentId: string]: number });
            await props.startNewConversationAsync(selectedAgents);
            props.setIsSelectAgentModalOpen(false);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    return (
        <Modal
            open={props.isSelectAgentModalOpen}
            onClose={() => props.setIsSelectAgentModalOpen(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalBoxStyle}>
                <Typography id="modal-title" variant="h4" component="h2">
                    Invite Agent
                </Typography>
                <IconButton
                    style={{ position: "absolute", right: 8, top: 8 }}
                    onClick={() => props.setIsSelectAgentModalOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Box className="modal-content">
                    <Typography component="h2">Select Agent</Typography>
                    <Box id="agent-select" sx={agentList}>
                        {availableAgentsRequest.loading === true ? (
                            <CircularProgress />
                        ) : availableAgentsRequest.error != null && availableAgentsRequest.error !== undefined ? (
                            <Box>Error: {availableAgentsRequest.error.message}</Box>
                        ) : availableAgentsRequest === undefined ||
                          availableAgentsRequest == null ||
                          availableAgentsRequest.availableAgents.length === 0 ? (
                            <Box>No available agent</Box>
                        ) : (
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {availableAgentsRequest.availableAgents.map((agent: IAgent, index: number) => {
                                        return (
                                            <FormControlLabel
                                                key={agent.agentId}
                                                control={
                                                    <Checkbox
                                                        key={agent.agentId}
                                                        name={agent.agentId}
                                                        onChange={() => handleAgentSelection(index)}
                                                        checked={props.selectedAgents[agent.agentId] !== undefined}
                                                    />
                                                }
                                                label={agent.agentName}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </FormControl>
                        )}
                    </Box>

                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Box padding={2}>
                            <Button variant="contained" onClick={() => props.setIsSelectAgentModalOpen(false)}>
                                Back
                            </Button>
                        </Box>
                        <Box padding={2}>
                            <Button variant="contained" color="success" onClick={handleSubmitSelectedAgents}>
                                Invite Agent
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default SelectAgentModal;
