import React from "react";
import { useNavigate } from "react-router-dom";

import { AppState, Auth0Provider, Auth0ProviderOptions, User } from "@auth0/auth0-react";

const Auth0ProviderWithRedirectCallback = ({
    children,
    props,
}: {
    children: React.ReactNode;
    props: Auth0ProviderOptions;
}) => {
    const navigate = useNavigate();
    if (!(props.domain && props.clientId && props.authorizationParams)) return null;
    const onRedirectCallback = (appState?: AppState, user?: User) => {
        console.log("App state:", JSON.stringify(appState));
        console.log("User logged in:", JSON.stringify(user));
        navigate(appState?.returnTo || window.location.pathname);
    };
    return (
        <Auth0Provider
            domain={props.domain}
            clientId={props.clientId}
            authorizationParams={props.authorizationParams}
            cacheLocation={props.cacheLocation}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithRedirectCallback;
