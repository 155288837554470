import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Socket } from "socket.io-client";
import { sendMessage, startNewConversation } from "../../components/chat/ManageConversation";
import Chatbox from "../../components/chat/chat_box/Chatbox";
import { SearchOptionTypeEnum } from "../../components/home/SearchOptionTypeEnum";
import { GetSolutionById } from "../../components/solution/ManageSolution";
import { OpenAIDefaultLLMSettings } from "../../configs/OpenAIDefaultLLMSettings";
import { SocketContext } from "../../context/SocketContext";
import { ISolution } from "../../models/interfaces/solution/ISolution";
import { IAgent } from "../../models/interfaces/workbench/agent/IAgent";
import { IAgentInstance } from "../../models/interfaces/workbench/agent/IAgentInstance";
import { OpenAIAgentConstants } from "../../models/interfaces/workbench/agent/OpenAIAgentConstants";
import { ChatSessionStatusEnum } from "../../models/interfaces/workbench/chat/ChatSessionStatusEnum";
import { IConversation } from "../../models/interfaces/workbench/chat/IChatSession";
import { LoadEntityTypeEnum } from "../../types/entity/LoadEntityEnum";
import { StringUtils } from "../../utils/string_utils";

const Chat: React.FC = () => {
    const { user } = useAuth0();
    const { socket } = useContext(SocketContext);

    const EmptyConversation: IConversation = {
        chatSessionId: "",
        ownerId: "",
        chatHistory: [],
        chatSessionStatus: ChatSessionStatusEnum.Active,
        llmSettings: OpenAIDefaultLLMSettings,
        participantProfiles: [],
    };
    const defaultSysPrompt = "Help user achieve their goal by recruiting agents and workout a solution.";

    // Handle data passed in from previous page
    const location = useLocation();
    const optionType = location.state?.option_type as SearchOptionTypeEnum;
    const userSearchQuery = location.state?.searchResult?.search_term as string;
    const searchResultEntityType = location.state?.searchResult?.entityType as string;
    const solutionId = location.state?.searchResult.solutionId as string;

    const [currentSolution, setCurrentSolution] = useState<ISolution>();

    const systemPrompt = (location.state?.searchResult.solutionSystemPrompt as string) ?? defaultSysPrompt;

    const [entityType] = useState<LoadEntityTypeEnum>(
        StringUtils.IsStringUndefinedNullOrEmpty(searchResultEntityType)
            ? LoadEntityTypeEnum.Agent
            : (searchResultEntityType as LoadEntityTypeEnum)
    );

    // Chat session state
    const [conversation, setConversation] = useState<IConversation>(EmptyConversation);

    // Active agent instances
    const [activeAgentInstances, setActiveAgentInstances] = useState<IAgentInstance[]>([]);

    // Keep agent prompt def cached {agentId: agent def}
    const [selectedAgents, setSelectedAgents] = useState<{
        [key: string]: IAgent;
    }>({});

    // Reference to the last message in chat box
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const sendMessageAsync = async ({
        socket,
        chatSessionId,
        userId,
        userName,
        userInput,
        recipientId,
    }: {
        socket: Socket;
        userId: string;
        chatSessionId: string;
        userName: string;
        userInput: string;
        recipientId?: string;
    }) => {
        if (StringUtils.IsStringUndefinedNullOrEmpty(chatSessionId)) return;
        if (StringUtils.IsStringUndefinedNullOrEmpty(userInput)) return;
        const msg = await sendMessage({
            socket: socket,
            chatSessionId: chatSessionId,
            userId: userId,
            userName: userName,
            userInputText: userInput,
            sendTo: recipientId ?? OpenAIAgentConstants.DefaultButlerId,
        });
        return msg;
    };

    const fetchNewConversationAsync = async (agentList: { [agentId: string]: number }): Promise<IConversation> => {
        // Scroll to the bottom of the chat box when a new conversation is started
        console.log(`Starting conversation`);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        const isButlerRequest = currentSolution === undefined ? true : false;
        const returnConversation: IConversation = await startNewConversation({
            socket: socket,
            systemPrompt: systemPrompt,
            userId: user?.sub ?? "",
            agentList: agentList,
            isButlerRequest: isButlerRequest,
        });
        return returnConversation;
    };

    const startConversationAsync = async (agentList: { [agentId: string]: number }): Promise<void> => {
        const returnConversation = await fetchNewConversationAsync(agentList);
        setConversation(returnConversation);
    };

    useEffect(() => {
        if (socket === undefined) {
            console.error(`Socket is undefined`);
            return;
        }

        const initChat = async () => {
            let newConv: IConversation;

            if (
                !StringUtils.IsStringUndefinedNullOrEmpty(solutionId) &&
                optionType === SearchOptionTypeEnum.RelatedSolution
            ) {
                const sol = await GetSolutionById(solutionId);
                setCurrentSolution(sol);
                console.log(`Starting conversation on page load with solution ${sol.solutionName}`);
                newConv = await fetchNewConversationAsync(sol?.agents ?? {});
            } else {
                console.log(`Starting conversation on page load`);
                newConv = await fetchNewConversationAsync(currentSolution?.agents ?? {});
            }
            newConv.participantProfiles.sort((a, b) => {
                if (
                    a.participantName?.toLowerCase().includes("assistant") ||
                    a.participantName?.toLowerCase().includes("manager") ||
                    a.participantName?.toLowerCase().includes("planner")
                ) {
                    return -1;
                }
                if (
                    b.participantName?.toLowerCase().includes("assistant") ||
                    b.participantName?.toLowerCase().includes("manager") ||
                    b.participantName?.toLowerCase().includes("planner")
                ) {
                    return 1;
                }
                return a.participantName < b.participantName ? -1 : 1;
            });
            setConversation(newConv);
        };

        initChat().catch((err) => {
            console.error(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        if (socket === undefined) return;
        const initUserMsg = async () => {
            // if (sendMsgCalled.current === true) return;
            if (StringUtils.IsStringUndefinedNullOrEmpty(conversation?.chatSessionId)) return;

            console.log("Asking user question on page load");
            let recp = OpenAIAgentConstants.DefaultButlerId;
            if (conversation?.participantProfiles !== undefined && conversation.participantProfiles.length > 0) {
                recp = conversation.participantProfiles[0].participantId;
            }

            await sendMessageAsync({
                socket: socket,
                chatSessionId: conversation.chatSessionId,
                userId: user?.sub ?? "",
                userName: user?.nickname ?? "",
                userInput: userSearchQuery,
                recipientId: recp,
            });

            // sendMsgCalled.current = true; // Make sure this is called only once
        };
        initUserMsg().catch((err) => {
            console.error(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation.chatSessionId, socket]);

    return (
        <Container maxWidth="xl">
            <Grid container>
                <Grid item xs={16}>
                    <Grid container justifyContent="center">
                        {/* main content display */}
                        <Grid item xs={16} sm={10}>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                {/* Output content */}
                                <Typography
                                    sx={{
                                        typography: {
                                            sm: "h2",
                                            xs: "h2",
                                        },
                                        height: "10vh",
                                    }}
                                >
                                    Chat with {entityType}
                                </Typography>
                                <Chatbox
                                    socket={socket}
                                    userId={user?.sub ?? ""}
                                    userName={user?.nickname ?? ""}
                                    conversation={conversation}
                                    selectedAgents={selectedAgents}
                                    setConversation={setConversation}
                                    startNewConversationAsync={startConversationAsync}
                                    setSelectedAgents={setSelectedAgents}
                                    getPrompt={() => {
                                        return systemPrompt;
                                    }}
                                    activeAgentInstances={activeAgentInstances}
                                    setActiveAgentInstances={setActiveAgentInstances}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Chat;
