import { Socket } from "socket.io-client";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../configs/config";
import * as ConversationEvent from "../../models/interfaces/events/conversation_events";
import { IAgent } from "../../models/interfaces/workbench/agent/IAgent";
import { IAgentInstance } from "../../models/interfaces/workbench/agent/IAgentInstance";
import { IAgentPromptView } from "../../models/interfaces/workbench/agent/IAgentPromptView";
import { IConversation } from "../../models/interfaces/workbench/chat/IChatSession";
import GetRequestHeader from "../../utils/auth_utils";

const ResetAgentVersionUrl = (agentId: string, versionId: number) =>
    `${REACT_APP_BACKEND_PROTECTED_URL}/agent/${agentId}/version/${versionId}`;

const GetAgentDefinitionUrl = (agentId: string, versionId: number) =>
    `${REACT_APP_BACKEND_PROTECTED_URL}/agent/${agentId}/version/${versionId}}`;

const GetAgentDefinitionOptions = (authToken?: string) => GetRequestHeader({ method: "GET", authToken: authToken });

const ResetAgentVersionOptions = (body: any, authToken?: string) =>
    GetRequestHeader({
        method: "PUT",
        body: JSON.stringify(body),
        authToken: authToken,
    });

const ActivateAgents = ({
    socket,
    chatSessionId,
    selectedAgents,
}: {
    socket: Socket | undefined;
    chatSessionId: string | undefined;
    selectedAgents: { [agentId: string]: number };
}) => {
    try {
        if (socket === undefined) throw new Error("Socket is undefined");

        socket.emit(ConversationEvent.ACTIVATE_SELECTED_AGENTS, {
            chatSessionId: chatSessionId,
            agentsToActivate: selectedAgents,
        });
    } catch (error) {
        console.error("An error occurred:", error);
    }
};

const GetActiveAgents = async ({
    conversation,
    socket,
}: {
    conversation: IConversation;
    socket: Socket | undefined;
}): Promise<IAgentInstance[]> => {
    let ret = [] as IAgentInstance[];

    try {
        if (socket === undefined || conversation === undefined || conversation == null) return ret;
        await socket.emitWithAck(
            ConversationEvent.LIST_ACTIVE_AGENTS,
            { chatSessionId: conversation?.chatSessionId },
            (data: IAgentInstance[], error: any) => {
                if (error) throw new Error(error);
                if (data) {
                    console.log(`Active agents: ${data}`);
                    ret = data;
                }
            }
        );
    } catch (error) {
        throw error;
    }
    return ret;
};

const ResetAgentVersion = async (agent: IAgent, authToken?: string): Promise<IAgentPromptView> => {
    const resp = await fetch(
        ResetAgentVersionUrl(agent.agentId, agent.versionId),
        ResetAgentVersionOptions(agent, authToken)
    );
    if (!resp.ok) throw new Error("API request failed: " + resp.text);
    const data = await resp.json();
    const agentPromptObj: IAgentPromptView = {
        ...data,
    };
    return agentPromptObj;
};

const GetAgentDefinitionById = async (agentId: string, versionId: number, authToken?: string): Promise<IAgent> => {
    return fetch(GetAgentDefinitionUrl(agentId, versionId), GetAgentDefinitionOptions(authToken))
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json() as Promise<IAgent>;
        })
        .then((data) => {
            return data;
        });
};

const GetBatchAgentDefinitionByIds = async (
    agentVersions: {
        [key: string]: number;
    },
    authToken?: string
): Promise<{ [key: string]: IAgent }> => {
    if (agentVersions === undefined) return {};
    const agentDefs = await Promise.all(
        Object.entries(agentVersions).map(async ([id, version]) => {
            const agentDef: IAgent = await GetAgentDefinitionById(id, version, authToken);
            return agentDef;
        })
    );
    const ret = Object.fromEntries(agentDefs.map((agentDef) => [agentDef.agentId, agentDef]));
    return ret;
};

export {
    ActivateAgents,
    GetActiveAgents,
    GetAgentDefinitionById,
    GetAgentDefinitionOptions,
    GetAgentDefinitionUrl,
    GetBatchAgentDefinitionByIds,
    ResetAgentVersion,
    ResetAgentVersionOptions,
    ResetAgentVersionUrl,
};
