import { withAuthenticationRequired, WithAuthenticationRequiredOptions } from "@auth0/auth0-react";

const ProtectedRoute = ({
    component,
    args,
}: {
    component: React.ComponentType<object>;
    args?: WithAuthenticationRequiredOptions;
}) => {
    const Component = withAuthenticationRequired(component, args);
    return <Component />;
};

export default ProtectedRoute;
